import UiNotificationBase from "./base"

const UiNotification = ({ path, style={}, children }) => {
    const d = 6
    return <UiNotificationBase path={path} style={style}>
        { 
            children || 
            <div className="ui-notification"
                style={{ width: d, height: d, ...style }}>
            </div> 
        }
    </UiNotificationBase>
}

export default UiNotification
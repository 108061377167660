import React, { Component } from 'react';
import { DropdownButton, MenuItem } from 'react-bootstrap';
import { translate } from 'react-i18next';
import { connect } from 'react-redux';

import * as contactActions from '../../redux/contacts/actions';

import { ALL_SELECTED } from '../../consts';

export class RepresentativeInfo extends Component {
  
  updateContactCard(props) {
    this.props.setRepresentative(props.contact.representativeId)
    if ( this.doMergeServicePoints(props) ) {
      const subAreaId = this.getSelectedSubArea(false)?.id
      this.props.setContactCardSubArea(subAreaId);
    }
  }
    
  UNSAFE_componentWillMount() {
    if ( this.props.contactCard && !this.props.contactCard.isModified ) {
      this.updateContactCard(this.props)
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if ( this.props.contact !== nextProps.contact ) {
      this.updateContactCard(nextProps)
    }
  }

  doMergeServicePoints(props) {
    return (props || this.props)?.contact?.project?.mergeServicePoints
  }

  handleSelectRepresentative(selectedRepresentativeId) {
    this.props.setRepresentative(selectedRepresentativeId);
    if ( this.doMergeServicePoints(this.props) ) {
      const servicePoint = this.getSelectedServicePoint(null, selectedRepresentativeId)
      if ( servicePoint ) {
        this.props.setContactCardServicePoint(servicePoint.id);
      }
    }
  }

  getSelectedRepresentativeName(representative) {
    if (representative && representative.user) {
      return `${representative.user.firstName} ${representative.user.lastName}`;
    } else if ( representative && representative.name ) {
      return representative.name
    }
    return this.props.t('representativeInfo.allRepresentatives');
  }

  getSelectedServicePointAddress(servicePointId) {
    if (servicePointId !== undefined) {
      const servicePoint = this.getSelectedServicePoint(servicePointId);
      if (servicePoint !== undefined) {
        return `${servicePoint.location.streetAddress} ${
          servicePoint.location.postalCode
        } ${servicePoint.location.postalArea}`;
      }
    }
  }

  getSelectedServicePointTravelInstructions(servicePointId) {
    if (servicePointId !== undefined) {
      const servicePoint = this.getSelectedServicePoint(servicePointId);
      if (servicePoint !== undefined) {
        return servicePoint.guide;
      }
    }
  }

  getSelectedServicePointName(servicePointId) {
    if (servicePointId !== undefined) {
      const servicePoint = this.getSelectedServicePoint(servicePointId);
      return servicePoint !== undefined
        ? servicePoint.name
        : this.props.t('postalArea');
    }
  }

  getSelectedSubArea(subAreaId) {
    if ( subAreaId ) {
      return this.props.subAreas.find(
        subArea => subArea.id === subAreaId
      );
    } else {
      const representativeId = this.props.contactCard.representativeId
      const subArea = this.props.subAreas.find(subArea => {
        if ( subArea?.representatives.find(representative => representative.id === representativeId) ) {
          return subArea
        }
      })
      return subArea
    }
  }

  getSelectedSubAreaName(subAreaId) {
    const subArea = this.getSelectedSubArea(subAreaId);
    return subArea
      ? subArea.name
      : this.props.t('subArea');
  }

  handleSelectServicePoint(selectedServicePointId) {
    this.props.setContactCardServicePoint(selectedServicePointId);
    this.props.setRepresentative(ALL_SELECTED);
  }

  handleSelectSubArea(selectedSubAreaId) {
    this.props.setContactCardSubArea(selectedSubAreaId);
    this.props.setRepresentative(ALL_SELECTED);
  }

  getSelectedServicePoint(servicePointId, _representativeId) {
    if ( servicePointId ) {
      return this.props.servicePoints.find(
        servicePoint => servicePoint.id === servicePointId
      );
    } else {
      const representativeId = _representativeId || this.props.contactCard.representativeId
      const servicePoint = this.props.servicePoints.find(servicePoint => 
        servicePoint.representatives.find(representative => 
          representative.id === representativeId
        )
      )
      return servicePoint
    }
  }

  getAvailableRepresentatives(props) {
    const root = this.doMergeServicePoints(props)
      ? this.getSelectedSubArea(props.contactCard.subAreaId)
      : this.getSelectedServicePoint(props.contactCard.servicePointId)
    return root != null ? root.representatives : []  
  }

  render() {
    const selectedServicePoint = this.getSelectedServicePoint(
      this.props.contactCard.servicePointId
    )
    const representatives = this.getAvailableRepresentatives(this.props)
    const selectedRepresentative = representatives.find(
      rep => rep.id === this.props.contactCard.representativeId
    )
    return (
      <div className="representative-info">
          <div className="rep-info-row" style={{ justifyContent: 'flex-start' }}>
          <div className="rep-info-row-column" style={{ justifyContent: 'center' }}>
            {
              this.doMergeServicePoints(this.props) &&
              <DropdownButton
                id="select-subArea"
                title={this.getSelectedSubAreaName(
                  this.props.contactCard.subAreaId
                ) || ''}
              >
              {this.props.subAreas.map(subArea => {
                return (
                  <MenuItem
                    eventKey={subArea.id}
                    key={subArea.id}
                    active={this.props.contactCard.subAreaId === subArea.id}
                    onSelect={eventKey =>
                      this.handleSelectSubArea(eventKey)
                    }
                  >
                    { subArea.name }
                  </MenuItem>
                );
              })}
              </DropdownButton>
            }
            {
              !this.doMergeServicePoints(this.props) &&
              <DropdownButton
                id="select-servicePoint"
                title={this.getSelectedServicePointName(
                  this.props.contactCard.servicePointId
                ) || ''}
              >
              {this.props.servicePoints.map(servicePoint => {
                return (
                  <MenuItem
                    eventKey={servicePoint.id}
                    key={servicePoint.id}
                    active={this.props.contactCard.servicePointId === servicePoint.id}
                    onSelect={eventKey =>
                      this.handleSelectServicePoint(eventKey)
                    }
                  >
                    { servicePoint.name }
                  </MenuItem>
                );
              })}
              </DropdownButton>
            }
          </div>

        <div className="rep-info-row-column" style={{ width: '35%', borderRight: '1px solid #ccc' }}>
          <div style={{ width: '100%', border: 'none', padding: 0 }}>
            <DropdownButton
              id="select-representative"
              title={this.getSelectedRepresentativeName(selectedRepresentative) || ''}
            >
              <MenuItem
                active={this.props.contactCard.representativeId === ALL_SELECTED}
                eventKey={ALL_SELECTED}
                key={ALL_SELECTED}
                onSelect={eventKey => this.handleSelectRepresentative(eventKey)}
              >
                {this.props.t('representativeInfo.allRepresentatives')}
              </MenuItem>
              <MenuItem divider />
              {representatives.map(representative => {
                return (
                  <MenuItem
                    active={
                      representative.id ===
                      this.props.contactCard.representativeId
                    }
                    eventKey={representative.id}
                    key={representative.id}
                    onSelect={eventKey =>
                      this.handleSelectRepresentative(eventKey)
                    }
                  >
                    {this.getSelectedRepresentativeName(representative)}
                  </MenuItem>
                );
              })}
            </DropdownButton>
            <div style={{ fontSize: 12, paddingLeft: 5 }}>
              {(() => {
                if ( selectedRepresentative && selectedRepresentative !== ALL_SELECTED ) {
                  return selectedRepresentative.phoneNumber
                }
              })()}
            </div>
          </div>
        </div>
        </div>
        <div className="rep-info-row">
          <div className="rep-info-row-column">
            <span>{this.props.t('servicePoint')}</span>
            <span>
              {this.getSelectedServicePointName(
                this.props.contactCard.servicePointId
              )}
            </span>
          </div>
          <div className="rep-info-row-column">
            <span>{this.props.t('streetAddress')}</span>
            <span>
              {this.getSelectedServicePointAddress(
                this.props.contactCard.servicePointId
              )}
            </span>
          </div>
          <div className="rep-info-row-column">
            <span>{this.props.t('travelInstructions')}</span>
            <span>
              {this.getSelectedServicePointTravelInstructions(
                this.props.contactCard.servicePointId
              )}
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => ({
  contactCard: state.contacts.contactCard,
  servicePoints: state.servicePoints.subAreaServicePoints,
  suggestionsPreviews: state.call.suggestionsPreviews,
  contact: state.contacts.contact,
  subAreas: state.projects.subAreas
});

const mapDispatchToProps = dispatch => ({
  setRepresentative: representativeId => {
    dispatch(contactActions.setContactCardModified())
    dispatch(
      contactActions.setRepresentative({
        representativeId
      })
    );
  },
  setContactCardServicePoint: servicePointId => {
    dispatch(contactActions.setContactCardModified())
    dispatch(
      contactActions.setContactCardServicePoint({
        servicePointId
      })
    );
  },
  setContactCardSubArea: subAreaId => {
    dispatch(contactActions.setContactCardModified())
    dispatch(
      contactActions.setContactCardSubArea({
        subAreaId
      })
    );
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(
  translate('translations')(RepresentativeInfo)
);

import { Alert, Glyphicon } from 'react-bootstrap'
import { usePatchUiNotificationMutation } from '../../rtk/ui-notifications-api'
import UiNotificationBase from './base'

const UiNotificationAlert = ({ path, bsStyle="danger", style={}, children, glyphicon="exclamation-sign" }) => {
    const [patchUiNotification] = usePatchUiNotificationMutation()
    return <UiNotificationBase path={path}>
        <Alert bsStyle={bsStyle} style={style} onDismiss={() => {
            patchUiNotification(path)
        }}>
            { glyphicon && <Glyphicon glyph={glyphicon} style={{ marginRight: 8 }}/> }
            { children }
        </Alert>
    </UiNotificationBase>    
}

export default UiNotificationAlert
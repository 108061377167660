import { useGetUiNotificationsQuery } from "../../rtk/ui-notifications-api"

const UiNotificationBase = ({ path, children }) => {
    const { data } = useGetUiNotificationsQuery(null, {
        pollingInterval: 30 * 1000
    })
    if ( !data || !data?.find(e => e.startsWith(path)) ) {
        return null
    }
    return children
}

export default UiNotificationBase
import React, { useState, useEffect } from 'react';
import { translate } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { Table } from 'react-bootstrap';
import { BusyIndicator } from '../../components';
import { Button, Glyphicon } from 'react-bootstrap';
import { push } from 'react-router-redux';

const AdminTable = ({ t, ...props }) => {
  const dispatch = useDispatch();

  const [state, setState] = useState({
    openInactives: false,
    search: "",
    delete: [],
    update: [],
    insert: [],
    newId: -1
  })

  const redirect = (url) => {
    dispatch(push(url));
  }

  const chevronStyle = {
    float: "right"
  }

  const subheadingStyle = {
    cursor: "pointer",
    fontWeight: "bold",
    background: "linear-gradient(180deg, rgba(0,0,0,.01) 0%, rgba(255,255,255,1) 100%, rgba(0,0,0,1) 100%)",
    width: '100%',
    marginBottom: state.openInactives ? "0" : "20px"
  }

  const btnStyle = {
    color: "#ffffff",
    backgroundColor: "#a51890",
    borderColor: "#a51890",
    fontSize: "16px",
    marginRight: "10px",
  }

  const searchFilter = row => {
    try {
      if (row.id < 0 || state.search === "") {
        return true;
      }
      const split = state.search.split(" ")
      for (let key in row) {
        const column = props.columns.find(column => column.key === key)
        if ( !column ) continue
        let oValue = row[key]
        if ( column.searchString ) {
          oValue = column.searchString(row)
        }
        if ( !column.disableSearch && oValue && typeof oValue === "string") {
          const value = oValue.toLowerCase();
          if (split.every(word => value.includes(word.toLowerCase()))) {
            return true
          }
        }
      }  
    } catch (e) {
      console.error(e)
    }
    return false
  }

  const onUpdate = row => {
    let insert = state.insert
    let update = state.update
    if ( row.id < 0 ) {
      insert = insert.map(_row => _row.id === row.id ? row : _row)
    } else {
      update = [
        ...update.filter(
          _row => _row.id !== row.id
        ),
        row
      ]
    }
    setState({
      ...state,
      insert,
      update
    })
  }

  const onDelete = id => {
    setState({
      ...state,
      insert: state.insert.filter(row => row.id !== id),
      delete: state.insert.find(row => row.id === id) ? state.delete : [...state.delete, id],
      update: state.update.filter(row => row.id !== id)
    })
  }

  const getRows = rows => {
    return [...rows, ...state.insert]
      .map(row => state.update.find(_row => _row.id === row.id) || row)
      .filter(row => !state.delete.find(_id => row.id === _id))
      .filter(searchFilter)
  }

  const getTable = (columns, rows) => {
    return (
      <Table>
        <thead>
          <tr>
            {columns.map(column => 
              <th key={column.key} style={{ borderBottom: 0, ...(column.style || {}) }}>
                {column.label}
              </th>
            )}
            {props.deleteRow && <th style={{ borderBottom: 0 }}></th>}
          </tr>
        </thead>
        <tbody>
          {rows.map((row, i) =>
            <tr key={i} className={row._disabled ? "third-of-opacity" : ""}>
              {columns.map(column => {
                const tdStyle = column.tdStyle || {}
                const text = column.getText 
                  ? column.getText(row[column.key]) 
                  : row[column.key];
                const content = <>
                  {text}{column.cellSuffix?.(row)}
                </>
                if (column.component) {
                  return (
                    <td className={column.tdClass} key={column.key} style={{verticalAlign: 'middle', ...tdStyle}}>
                      {column.component(content, text, row, column, onUpdate)}
                    </td>
                  );
                } else if (column.onClick || column.href) {
                  return (
                    <td className={column.tdClass} key={column.key} style={tdStyle}>
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <Button
                          href={column.href?.(row)}
                          style={{ 
                            display: "flex",
                            fontSize: "15px",
                            alignItems: "center"
                          }}
                          bsStyle="link"
                          className="add-button"
                          onClick={e => {
                            if (column.href) {
                              redirect(column.href(row))
                              e.preventDefault()
                            } else {
                              column.onClick(row)
                            }
                          }}
                        >
                          {content}
                        </Button>
                      </div>
                    </td>
                  )
                } else {
                  return (
                    <td className={column.tdClass} key={column.key} style={{verticalAlign: 'middle', ...tdStyle}}>
                      {content}
                    </td>
                  )
                }
              })}
              {props.deleteRow && 
                <td style={{textAlign: "right", verticalAlign: "middle"}}>
                  <Button
                    bsStyle="link"
                    onClick={() => onDelete(row.id)}
                  >
                    <Glyphicon glyph="remove" />
                  </Button>
                </td>
              }
            </tr>
          )}
        </tbody>
      </Table>
    )
  }

  useEffect(() => {
    setState({
      ...state,
      delete: [],
      update: [],
      insert: [],
      newId: -1
    })
  }, [props.rows])

  return (
    <>
      {props.isLoading &&
        <div style={{ width: '100%' }}>
          <BusyIndicator />
        </div>
      }
      {!props.isLoading &&
        <div className="section">
          <div className="search" style={{ 
            display: 'flex', 
            alignItems: 'center', 
            flex: 1,
            margin: 5,
            marginTop: 10,
            marginBottom: 10,
            ...(props.searchStyle || {})
          }}>
            <Glyphicon glyph="search" />
            <input 
              style={{ 
                flex: 1,
                border: 0,
                backgroundColor: "#fafafa",
                borderRadius: 20,
                paddingLeft: 10,
                paddingBottom: 4,
                marginBottom: 0,
                textAlign: 'left',
                height: 29,
                fontSize: 14
              }}
              autoFocus
              value={state.search}
              onChange={event => setState({...state, search: event.target.value})}
            />
          </div>
          {getTable(props.columns, getRows(props.rows).filter(row => !row._inactive))}
          {!props.noInactives &&
            <>
              <div 
                className="subheading" 
                onClick={() => setState({...state, openInactives: !state.openInactives})} 
                style={subheadingStyle}
              >
                {t("inactives")} ({getRows(props.rows).filter(row => row._inactive).length})
                <Glyphicon style={chevronStyle} glyph={state.openInactives ? "chevron-down" : "chevron-up"} />
              </div>
              {state.openInactives &&
                <div className="section" style={{ paddingBottom: 0, display: "flex", flexFlow: "column", width: '100%', borderBottom: 0 }}>
                  {getTable(props.columns, getRows(props.rows).filter(row => row._inactive))}
                </div>
              }
            </>
          }
          {!props.isLoading && props.saveBtn &&
            <div style={{ display: 'flex', width: '100%' }}>
              <div style={{ flex: 1 }}>
                <Button
                  bsStyle="primary"
                  disabled={props.isSaveDisabled?.()}
                  onClick={() => {
                    props.onSave?.(
                      state.insert.map(row => ({...row, id: undefined})), 
                      state.update, 
                      state.delete
                    )}
                  }
                >
                  {t('save')}
                </Button>
                <Button
                  bsStyle="link"
                  onClick={() => setState({
                    ...state, 
                    search: "",
                    delete: [], 
                    update: [], 
                    insert: []
                  })}
                >
                  {t('cancel')}
                </Button>
              </div>
              <div>
                <Button
                  style={{ ...btnStyle, fontSize: "12px" }}
                  bsStyle="primary"
                  onClick={() => {
                    setState({
                      ...state,
                      newId: state.newId - 1,
                      insert: [...state.insert, props.getInsert(state.newId)]
                    });
                  }}
                >
                  <Glyphicon glyph="plus" />
                </Button>
              </div>
            </div>
          }
          {!props.isLoading && props.newBtn &&
            <Button
              style={btnStyle}
              bsStyle="primary"
              onClick={props.newBtn.onClick}
            >
              <Glyphicon glyph="plus" />
              <span>{" " + props.newBtn.label}</span>
            </Button>
          }
        </div>
      }
    </>
  )
}

export default translate('translations')(AdminTable)
import { api } from "./api"

export const uiNotificationsApi = api.injectEndpoints({
	endpoints: (builder) => ({
        getUiNotifications: builder.query({
            query: path => ({
                url: `/ui-notifications`,
                params: path ? { path } : undefined
            }),
            providesTags: (result, error, arg) => {
                return result?.map(path => ({ type: "UiNotification", id: path })) || []
            }
		}),
        patchUiNotification: builder.mutation({
            query: (path) => ({
                url: `/ui-notifications`,
                method: 'PATCH',
                body: {path}
            }),
            invalidatesTags: (result, error, path) => {
                return [{ type: 'UiNotification' }]
            }
        })
	})
})

export const { 
	useGetUiNotificationsQuery,
    usePatchUiNotificationMutation
} = uiNotificationsApi
import { useState, useEffect } from 'react'
import { Modal, Button, Nav, NavItem, Alert } from 'react-bootstrap'
import { translate } from 'react-i18next';
import { connect } from 'react-redux';
import DatePicker from '../../components/date-picker'
import moment from 'moment'
import { UiNotification, UiNotificationAlert } from '../../components';

const MsGraphApiProxyModal = ({ id, proxy, setProxy, show, onClose, t }) => {

    const [tab, setTab] = useState(0)
    const [url, setUrl] = useState('')
    const [tenant, setTenant] = useState('')
    const [scope, setScope] = useState('')
    const [clientId, setClientId] = useState('')
    const [clientSecret, setClientSecret] = useState('')
    const [keyExpiresAt, setKeyExpiresAt] = useState(new Date())
    const [contactPerson, setContactPerson] = useState('')
    const [contactEmail, setContactEmail] = useState('')
    const [notes, setNotes] = useState('')

    useEffect(() => {
        if ( show ) {
            setUrl(proxy?.url || '')
            setTenant(proxy?.tenant || '')
            setScope(proxy?.scope || '')
            setClientId(proxy?.clientId || '')
            setClientSecret(proxy?.clientSecret || '')
            setKeyExpiresAt(proxy?.keyExpiresAt ? moment(proxy.keyExpiresAt) : new Date())
            setContactPerson(proxy?.contactPerson || '')
            setContactEmail(proxy?.contactEmail || '')
            setNotes(proxy?.notes || '')
        }
    }, [proxy, show])

    const rowStyle = {
        display: "flex",
        flexFlow: "row",
        marginBottom: "5px"
    }
    const inputLabelStyle = {
        width: "120px",
        minWidth: "120px",
        display: "flex",
        height: "30px",
        alignItems: "center"
    }
    const inputStyle = {
        height: "30px",
        paddingLeft: "7px",
        border: "solid 1px #d6d6d6",
        borderRadius: "4px",
        textAlign: "left",
        width: "100%",
        fontSize: "14px",
        marginBottom: 0
    }
    const selectedStyle = {
        borderRadius: '5px 5px 0 0', 
        border: '1px solid #ccc', 
        borderWidth: '1px 1px 0 1px', 
        overflow: 'hidden'
    }
    const nonSelectedStyle = {
        border: '1px solid #ccc', 
        borderWidth: '0 0 1px 0', 
        overflow: 'hidden', 
        borderRadius: '5px 5px 0 0'
    }

    return (
        <Modal className="outlook-status-modal" onHide={onClose} show={show}>
            <Modal.Header closeButton={false} style={{ paddingBottom: 0, paddingLeft: 0, paddingRight: 0, borderBottom: 0 }}>
                <Nav variant="tabs" style={{ display: 'flex' }}>
                    <div style={{ width: 15, borderBottom: '1px solid #ccc'}}></div>
                    <NavItem onClick={() => setTab(0)} style={ tab === 0 ? selectedStyle : nonSelectedStyle }>
                        Asetukset
                    </NavItem>
                    <NavItem onClick={() => setTab(1)} style={ tab === 1 ? selectedStyle : nonSelectedStyle }>
                        Lisätiedot
                        <div style={{ position: "relative", display: "inline" }}>
                            <UiNotification 
                                path={`main-menu/clients/client_${id}/proxy/additional-info`} 
                                style={{ top: 0, right: -7 }} 
                            />
                        </div>
                    </NavItem>
                    <div style={{ flex: 1, borderBottom: '1px solid #ccc'}}></div>
                </Nav>
            </Modal.Header>
            <Modal.Body>
                { tab === 0 &&
                    <>
                        <div style={ rowStyle }>
                            <div className="input-label" style={ inputLabelStyle }>URL</div>
                            <input style={ inputStyle } type="text" value={ url } onChange={ e => setUrl(e.target.value) }/>
                        </div>
                        <div style={ rowStyle }>
                            <div className="input-label" style={ inputLabelStyle }>Tenant</div>
                            <input style={ inputStyle } type="text" value={ tenant } onChange={ e => setTenant(e.target.value) }/>
                        </div>
                        <div style={ rowStyle }>
                            <div className="input-label" style={ inputLabelStyle }>Scope</div>
                            <input style={ inputStyle } type="text" value={ scope } onChange={ e => setScope(e.target.value) }/>
                        </div>
                        <div style={ rowStyle }>
                            <div className="input-label" style={ inputLabelStyle }>Client ID</div>
                            <input style={ inputStyle } type="text" value={ clientId } onChange={ e => setClientId(e.target.value) }/>
                        </div>
                        <div style={ rowStyle }>
                            <div className="input-label" style={ inputLabelStyle }>Client secret</div>
                            <input style={ inputStyle } type="text" value={ clientSecret } onChange={ e => setClientSecret(e.target.value) }/>
                        </div>
                    </> 
                }
                { tab === 1 &&
                    <>
                        <UiNotificationAlert path={`main-menu/clients/client_${id}/proxy/additional-info`} style={{ marginBottom: 10 }}>
                            Avain vanhenee pian.
                        </UiNotificationAlert>
                        <div style={ rowStyle }>
                            <div className="input-label" style={ inputLabelStyle }>Avain vanhenee</div>
                            <DatePicker
                            style={{ padding: 0, marginTop: 3, marginLeft: 3 }}
                                date={moment(keyExpiresAt).toDate()}
                                onDayChange={date => {
                                    setKeyExpiresAt(date)
                                }}
                                displayFormat={'L'}
                            />
                        </div>
                        <div style={ rowStyle }>
                            <div className="input-label" style={ inputLabelStyle }>Yhteyshenkilö</div>
                            <input style={ inputStyle } type="text" value={ contactPerson } onChange={ e => setContactPerson(e.target.value) }/>
                        </div>
                        <div style={ rowStyle }>
                            <div className="input-label" style={ inputLabelStyle }>Sähköposti</div>
                            <input style={ inputStyle } type="text" value={ contactEmail } onChange={ e => setContactEmail(e.target.value) }/>
                        </div>
                        <div style={ rowStyle }>
                            <div className="input-label" style={{ ...inputLabelStyle, verticalAlign: "top" }}>Muistiinpanot</div>
                            <textarea style={{ ...inputStyle, height: 100 }} type="text" value={ notes } onChange={ e => setNotes(e.target.value) }/>
                        </div>
                    </>
                }
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => {
                    setProxy({
                        url,
                        tenant,
                        scope,
                        clientId,
                        clientSecret,
                        keyExpiresAt: moment(keyExpiresAt).toISOString(),
                        contactPerson,
                        contactEmail,
                        notes
                    })
                    onClose()
                }}>
                    {t('ok')}
                </Button>
                <Button onClick={onClose}>
                    {t('cancel')}
                </Button>
            </Modal.Footer>
        </Modal>
    )
}

const mapStateToProps = state => ({
})
  
const mapDispatchToProps = dispatch => ({
})

export default connect(mapStateToProps, mapDispatchToProps)(
    translate('translations')(MsGraphApiProxyModal)
  );